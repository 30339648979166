import { client } from 'utils/axios';
import {
  LocationPath,
  LocationPaths,
  LocationPathsResponse,
} from 'types/location.type';

class LocationService {
  private locationTranslations: LocationPaths = [];

  async getLocationLinkSlugTranslations(): Promise<LocationPaths> {
    const { data } = await client.get<LocationPathsResponse>(
      '/v1/customer/location/paths'
    );

    const filteredData: { tr: string[]; en: string[] } = {
      tr: [],
      en: [],
    };

    let offset = 0;

    for (const idx in data.en) {
      if (data.tr[idx]) {
        if (data.en[idx].includes('korfez')) {
          filteredData.tr.push(data.tr[+idx]);
          filteredData.en.push(data.en[+idx + ++offset]);
        } else {
          filteredData.tr.push(data.tr[+idx]);
          filteredData.en.push(data.en[+idx + offset]);
        }
      }
    }

    const mappedLocationPaths: LocationPaths = [];

    for (const idx in filteredData.tr) {
      mappedLocationPaths.push({
        tr: filteredData.tr[idx],
        en: filteredData.en[idx],
      });
    }

    return mappedLocationPaths;
  }

  getLocationLink(loc: string): LocationPath {
    return this.locationTranslations.find((s) =>
      Object.values(s).includes(loc)
    )!;
  }

  setLocationLinks(locations: LocationPaths): void {
    this.locationTranslations = locations;
  }
}

export const locationService = new LocationService();
