import BButton from 'components/common/BButton';
import BDivider from 'components/common/BDivider';
import Modal from 'components/common/Modal';
import { AuthModalMode } from 'enums/authModalMode';
import { Locale } from 'enums/locale';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { Fragment, FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import { authService } from 'services/AuthService';
import { localizationService } from 'services/LocalizationService';
import { useAuthModalState } from 'states/useAuthModalState';
import { useUserState } from 'states/useUserState';
import { BottomNavigationMenuItem } from 'types/layout.type';
import BottomMenuItem from './BottomMenuItem';
import { useNotificationState } from 'states/useNotificationState';
import { useLocale } from 'hooks/useLocale';
import CdnSvg from 'components/common/CdnSvg';

interface BottomMenuSeeMoreModalProps {
  show: boolean;
  onClose: () => void;
}

const BottomMenuSeeMoreModal: FunctionComponent<
  BottomMenuSeeMoreModalProps
> = ({ show, onClose }) => {
  const router = useRouter();
  const { locale } = useLocale();
  const { t } = useTranslation('navbar');
  const { isUserLoggedIn, setIsUserLoggedIn } = useUserState();
  const { setAuthMode, setShowAuthModal } = useAuthModalState();
  const { getReservationsPageBadges } = useNotificationState();

  const openSignUpModal = () => {
    setAuthMode(AuthModalMode.REGISTER);
    setShowAuthModal(true);
    setTimeout(() => {
      onClose();
    }, 10);
  };

  const logout = () => {
    authService.logout();
    setIsUserLoggedIn();
    toast.success(t('common:auth.logout.success'), { toastId: 'logout' });
  };

  const menuItems: BottomNavigationMenuItem[] = [
    { label: t('bottomMenu.homepage'), icon: 'house.svg', href: '/' },
    {
      label: t('bottomMenu.organizations'),
      icon: 'confetti.svg',
      href: '/hizmetler',
    },
    {
      label: t('campaigns'),
      icon: 'gift.svg',
      href: '/kampanyalar',
    },
    ...(!isUserLoggedIn
      ? [
          { label: t('becomePartner'), href: '/partner', icon: 'boat.svg' },
          {
            label: t('register'),
            icon: 'sign-in.svg',
            onClick: openSignUpModal,
          },
        ]
      : []),
    ...(isUserLoggedIn
      ? [
          {
            label: t('reservations'),
            href: '/rezervasyonlarim',
            icon: 'anchor.svg',
            badge: getReservationsPageBadges(),
          },
          {
            label: t('profile'),
            icon: 'user-rectangle.svg',
            href: '/kisisel-bilgilerim',
          },
          {
            label: t('securityAndPrivacy'),
            icon: 'shield-warning.svg',
            href: '/gizlilik',
          },
          { label: t('logout'), icon: 'sign-out.svg', onClick: logout },
        ]
      : []),
    {
      label: t('helpCenter'),
      icon: 'question.svg',
      href: '/yardim-merkezi',
    },
    {
      label: t('contact'),
      icon: 'phone.svg',
      href: '/iletisim',
    },
  ];

  const getLocaleLink = (locale: Locale) => {
    return localizationService.getTranslatedRouteWithSlugs(router, locale);
  };

  const languageMenuItem = {
    title: {
      label: t('language'),
      icon: 'globe-straight.svg',
    },
    languages: [
      {
        label: 'Türkçe',
        locale: Locale.TR,
        link: getLocaleLink(Locale.TR),
      },
      {
        label: 'English',
        locale: Locale.EN,
        link: getLocaleLink(Locale.EN),
      },
    ],
  };

  const isItemActive = (menuItem: BottomNavigationMenuItem): boolean => {
    return menuItem.href === router.pathname;
  };

  return (
    <Modal
      show={show}
      setShow={onClose}
      modalTitleClass="!items-start"
      modalTitle={
        <div className="flex items-center gap-3">
          <CdnSvg className="h-11" src="teknevia-logo.svg" />
          <CdnSvg className="h-5" src="teknevia-logo-text.svg" />
        </div>
      }
    >
      <div>
        {isUserLoggedIn ? (
          <a
            rel="nofollow noreferrer noopener"
            title={t('contactSupportTeam')}
            target="_blank"
            href={t('common:whatsappLink')}
          >
            <div className="mb-4 flex items-center gap-2 rounded-md bg-primary-500 p-3 text-white">
              <CdnSvg src="whatsapp-logo-white.svg" size={24} />
              {t('contactSupportTeam')}
            </div>
          </a>
        ) : null}
        <ul>
          {menuItems.map((menuItem, idx) => (
            <BottomMenuItem
              menuItem={menuItem}
              variant="seeMore"
              wrapperClass="mb-4"
              isActive={isItemActive(menuItem)}
              key={`menu-item-${idx}`}
            />
          ))}
        </ul>
        <BDivider colorClass="border-slate-300" className="my-8" />
        <div>
          <BottomMenuItem
            menuItem={languageMenuItem.title}
            variant="seeMore"
            wrapperClass="mb-4"
          />
          <div className="grid grid-cols-2 gap-4">
            {languageMenuItem.languages.map((item) => (
              <Fragment key={item.locale}>
                {item.locale === locale ? (
                  <BButton
                    priority="outlined"
                    variant="secondary"
                    className="shadow-none"
                    disabled={router.locale === item.locale}
                  >
                    {item.label}
                  </BButton>
                ) : (
                  <a href={item.link}>
                    <BButton
                      priority="outlined"
                      variant="secondary"
                      className="shadow-none"
                      disabled={router.locale === item.locale}
                      onClick={() =>
                        localizationService.setServicesLocales(item.locale)
                      }
                      fluid
                    >
                      {item.label}
                    </BButton>
                  </a>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BottomMenuSeeMoreModal;
