import Head from 'next/head';
import Script from 'next/script';
import { FunctionComponent, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { HelmetSettings } from 'types/seo.type';
import SeoContent from './SeoContent';

interface SeoWrapperProps {
  children: ReactNode | ReactNode[];
  helmetSettings: HelmetSettings;
  content?: string;
}

const SeoWrapper: FunctionComponent<SeoWrapperProps> = ({
  children,
  helmetSettings,
  content,
}) => {
  const { title, script, ...restHelmetSettings } = helmetSettings;

  return (
    <>
      <Helmet {...restHelmetSettings} />
      <Head>
        <title>{title}</title>
      </Head>
      {script ? (
        <Script
          id="schema"
          type="application/ld+json"
          strategy="beforeInteractive"
          dangerouslySetInnerHTML={{
            __html: script,
          }}
        />
      ) : null}
      {children}
      {content ? <SeoContent content={content} /> : null}
    </>
  );
};

export default SeoWrapper;
