import BImage from 'components/common/BImage';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent } from 'react';
import SearchBar from '../Layout/Common/SearchBar';

const HomeHero: FunctionComponent = () => {
  const { t } = useTranslation('home');
  // useNavbarIntersection('.hero-image');

  return (
    <section className="hero-section pb-12">
      <div id="hero" className="hero-image">
        <BImage
          src="https://cdn.teknevia.com/storefront/homepage/homepage-hero-min.png?format=auto&quality=60"
          alt="Teknevia"
          className="h-full w-full object-cover"
          priority
          layout="fill"
          objectPosition="50% 0"
        />
      </div>
      <div className="flex flex-col items-center text-center">
        <span className="whitespace-wrap mb-10 block text-4xl font-medium lg:whitespace-nowrap lg:text-5xl">
          {t('hero.title')}
        </span>
        <SearchBar className="mb-6 text-xl text-black" from="homepage" />
        <h1 className="block max-w-lg text-2xl ">
          Teknevia: {t('hero.subTitle')}
        </h1>
        <span className="mt-4 text-sm font-medium">
          {t('common:footer.tekneviaSection.tursab')}
        </span>
      </div>
      <style jsx>{`
        .hero-section {
          height: 40vh;
          min-height: 450px;
          color: var(--clr-white);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .hero-image {
          height: calc(40vh + 96px + 2rem);
          min-height: calc(450px + 96px + 2rem);
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          filter: brightness(0.7);
        }
      `}</style>
    </section>
  );
};

export default HomeHero;
